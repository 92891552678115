import React from 'react'
import logo from './logo.svg'
import './App.css'
import { ColorIndicator } from './ColorIndicators'
import * as polished from 'polished'
import { ColorPalletes } from './ColorPalette'
import styled from 'styled-components'

type AvailableFunctions =
  | 'lighten'
  | 'darken'
  | 'desaturate'
  | 'saturate'
  | 'grayscale'

const Columns = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`

function App() {
  const [colors, setColor] = React.useState<string[]>([])
  const [functions, setFunctions] = React.useState<
    [AvailableFunctions, number][]
  >([
    ['lighten', 0.33],
    ['darken', 0.36],
  ])

  const updateColors = (colors: string[]) => {
    setColor(colors)
  }

  const isValidColor = (str: string) => {
    const s = new Option().style
    s.color = str
    return s.color !== ''
  }

  return (
    <div className="App">
      <FunctionSelectors functions={functions} setFunctions={setFunctions} />
      <ColorPalletes updateColors={updateColors} />
      <Columns>
        {colors.map((color, index) => {
          if (isValidColor(color)) {
            return (
              <ColorIndicator
                color={color}
                swap={false}
                functions={functions}
              />
            )
          } else return <div />
        })}
      </Columns>
    </div>
  )
}

function FunctionSelectors(props: {
  functions: [AvailableFunctions, number][]
  setFunctions(newFunctions: [AvailableFunctions, number][]): void
}) {
  const [tempFunction, setTempFunction] =
    React.useState<AvailableFunctions>('darken')

  function updateFunction(newFunction: AvailableFunctions, index: number) {
    const newFunctions = [...props.functions]
    newFunctions[index] = [newFunction, newFunctions[index][1]]
    props.setFunctions(newFunctions)
  }

  function updateFactor(newFactor: number, index: number) {
    const newFunctions = [...props.functions]
    newFunctions[index] = [newFunctions[index][0], newFactor]
    props.setFunctions(newFunctions)
  }

  function addFunction() {
    props.setFunctions([...props.functions, [tempFunction, 0.33]])
    setTempFunction('darken')
  }

  return (
    <div>
      {
        // Map over each function and create a dropdown for each as well as a slider for the factor
        props.functions.map(([functionName, factor], index) => (
          <div>
            <select
              value={functionName}
              onChange={(e) => {
                updateFunction(e.target.value as AvailableFunctions, index)
              }}
            >
              <option value="lighten">Lighten</option>
              <option value="darken">Darken</option>
              <option value="desaturate">Desaturate</option>
              <option value="saturate">Saturate</option>
              <option value="grayscale">Grayscale</option>
            </select>
            <input
              type="range"
              min={0}
              max={1}
              step={0.01}
              value={factor}
              onChange={(e) => {
                updateFactor(parseFloat(e.target.value), index)
              }}
            />
          </div>
        ))
      }
      <div>
        <select
          value={tempFunction}
          onChange={(e) => {
            setTempFunction(e.target.value as AvailableFunctions)
          }}
        >
          <option value="lighten">Lighten</option>
          <option value="darken">Darken</option>
          <option value="desaturate">Desaturate</option>
          <option value="saturate">Saturate</option>
        </select>
        <button onClick={addFunction}>Add Function</button>
      </div>
    </div>
  )
}

export default App
