import React from 'react'
import styled from 'styled-components'

const Palette = styled.div<{ color: string }>`
  width: 140px;
  height: 36px;

  display: grid;
  place-items: center;

  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.color};
`

const PaletteInput = styled.input`
  width: 80px;
  height: 24px;

  border-radius: 2px;
  border: none;
  background: rgba(255, 255, 255, 0.4);

  color: black;
`

const ColorPalleteWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  padding: 12px;
`

interface ColorPalettesProps {
  updateColors: (colors: string[]) => void
}

export function ColorPalletes(props: ColorPalettesProps) {
  const [colors, setColors] = React.useState<string[]>([
    '#d90285',
    '#dc3451',
    '#f0b925',
    '#00ade2',
    '#fa666d',
    '#5ebd84',
    '#7766c0',
  ])
  const [tempColor, setTempColor] = React.useState<string>('#cccccc')

  const addColor = () => {
    setColors([...colors, tempColor])
    props.updateColors([...colors, tempColor])
    setTempColor('#cccccc')
  }

  React.useEffect(() => {
    props.updateColors(colors)
  }, [])

  const updateColor = (index: number, newColor: string) => {
    const newColors = [...colors]
    newColors[index] = newColor
    setColors(newColors)
    props.updateColors(newColors)
  }

  return (
    <ColorPalleteWrapper>
      {colors.map((color, index) => (
        <Palette key={index} color={color}>
          <PaletteInput
            type="text"
            value={color}
            onChange={(e) => updateColor(index, e.target.value)}
          />
        </Palette>
      ))}
      <Palette color={tempColor}>
        <PaletteInput
          type="text"
          value={tempColor}
          name="color"
          onChange={(e) => setTempColor(e.target.value)}
        />
        <button onClick={() => addColor()}>Add</button>
      </Palette>
    </ColorPalleteWrapper>
  )
}
