import React from 'react'
import styled from 'styled-components'
import * as polished from 'polished'

const Palette = styled.div<{ color: string; textColor: string }>`
  width: 140px;
  height: 80px;

  display: grid;
  place-items: center;

  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.color};
  color: ${(props) => props.textColor};
`

const ColorIndicatorWrapper = styled.div`
  width: 154px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  padding: 12px;
`

export function ColorIndicator(props: {
  color: string
  swap: boolean
  functions: [string, number][]
}) {
  const [colors, setColors] = React.useState<string[][]>([])

  React.useEffect(() => {
    let newColors: [string, string][] = []
    newColors = props.functions.map((x, i) => {
      const [functionName, factor] = props.functions[i]
      // @ts-ignore
      const bg = polished[functionName](factor, props.color)
      const text = props.color

      return [bg, text]
    })
    setColors(newColors)
  }, [props.functions, props.color])

  return (
    <ColorIndicatorWrapper>
      <p>Regular</p>
      <Palette
        color={props.color}
        textColor={polished.readableColor(props.color)}
      >
        {props.color} <br />
        lum: {polished.readableColor(props.color)}
      </Palette>

      {colors.map((color, index) => (
        <>
          <p>
            {props.functions[index][0]}({props.functions[index][1]})
          </p>
          <Palette color={color[0]} textColor={color[1]}>
            Hello World! <br />
            lum: {polished.getLuminance(color[0])}
          </Palette>
        </>
      ))}
      {
        // Mixing
        (() => {
          const color = props.color

          if (props.functions.length < 2) {
            return <> </>
          }

          const [firstFun, firstFactor] = props.functions[0]
          const [secondFun, secondFactor] = props.functions[1]

          if (props.functions.length >= 3) {
            const [thirdFun, thirdFactor] = props.functions[2]
            // @ts-ignore
            const modifiedText = polished.mix(
              0.5,
              // @ts-ignore
              polished[secondFun](secondFactor, color),
              // @ts-ignore
              polished[thirdFun](thirdFactor, color)
            )
            // @ts-ignore
            const modifiedBg = polished[firstFun](firstFactor, color)

            return (
              <>
                <p>Mixing</p>
                <Palette color={modifiedBg} textColor={modifiedText}>
                  Hello World! <br />
                  lum: {polished.getLuminance(modifiedBg)}
                </Palette>
                <p>
                  bg: {firstFun}({firstFactor} <br />
                  text: {secondFun}({secondFactor}) + <br />
                  {thirdFun}({thirdFactor})
                </p>
              </>
            )
          }

          // @ts-ignore
          const modifiedText = polished[secondFun](secondFactor, color)
          // @ts-ignore
          const modifiedBg = polished[firstFun](firstFactor, color)

          return (
            <>
              <p>Mixing</p>
              <Palette color={modifiedBg} textColor={modifiedText}>
                Hello World! <br />
              </Palette>
              <p>
                bg: {firstFun}({firstFactor} <br />
                text: {secondFun}({secondFactor})
              </p>
            </>
          )
        })()
      }
    </ColorIndicatorWrapper>
  )
}
